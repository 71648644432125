/******************************************************************
 * 
 *   baccus Home 모듈
 *   2022.02.23 ~ 2022.02.23
 *   
 *   Copyright 2023 by YoungBin KO.
 * 
 * ****************************************************************/

import React, { useEffect, useState } from 'react';
import { Empty, Space, Table as Table2, Drawer, Modal } from 'antd';
import { Button, Table } from 'semantic-ui-react';

import getDataList from '../../common-lib/getDataList';

import '../../css/tableColor.css';
import '../../css/tabsColor.css';

import GoodsImgUpload from './goodsimage/GoodsImgUpload';
import GoodsNewAdd from './goodsimage/GoodsNewAdd';
import GoodsUpdate from './goodsimage/GoodsUpdate';

function UnregisteredImages() {

    const [ data, setData ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ currentRecord, setCurrentRecord ] = useState({});
    const [ openDrawer, setOpenDrawer ] = useState(false);
    const [ visible, setVisible ] = useState(false)
    const [ mode, setMode ] = useState('View');
    const [ drawerTitle, setDrawerTitle ] = useState('');

    const locale = { emptyText : <><Empty />요청한 데이터가 없습니다.</> };

    const columns = [
        {   title: 'No.',             dataIndex: 'rowNum',            key: 'rowNum',            align: 'right',           width: 30,        },
        {   title: '상품명',           dataIndex: 'goodsName',       key: 'goodsName',       align: 'left',          width: 80,        },
        {   title: '제조사/공급사',    dataIndex: 'companyName',       key: 'companyName',       align: 'center',          width: 80,        },
        {   title: '구분',            dataIndex: 'category',       key: 'category',       align: 'center',          width: 40,        },
        {   title: '용량',            dataIndex: 'volume',       key: 'volume',       align: 'center',          width: 40,        },
        {   title: '도수',            dataIndex: 'alcoholRate',       key: 'alcoholRate',       align: 'center',          width: 40,        },
        {   title: '본수',            dataIndex: 'bottlePerBox',       key: 'bottlePerBox',       align: 'center',          width: 40,        },
        {   title: '판매구분',        dataIndex: 'classification',       key: 'classification',       align: 'center',          width: 40,        },
        {   title: '상품 이미지',     dataIndex: 'imgFilename',         key: 'imgFilename',         align: 'center',          width: 70,        },
        {   title: '상품상태',        dataIndex: 'goodsStatus',       key: 'goodsStatus',       align: 'center',          width: 40,
            render : (text) => {
                return text === 'Y' ? '사용' : '사용불가'; 
            },
        },
        {
            title: '',
            dataIndex: 'operation',
            key: 'operation',
            align: 'center',
            width: 80,
            render : (_, record) => {
                return (
                    <>
                        <Space>
                            <Button className='operationButton' size='mini' 
                                onClick={(e) => { 
                                    e.stopPropagation(); 
                                    setCurrentRecord(record); 
                                    setDrawerTitle('상품 이미지 등록'); setMode('Upload'); 
                                    setOpenDrawer(true) }} >이미지등록</Button>
                            <Button className='operationButton' size='mini'
                                onClick={(e) => { 
                                    e.stopPropagation(); 
                                    setCurrentRecord(record); 
                                    setDrawerTitle('상품 수정하기'); 
                                    setMode('Update'); 
                                    setOpenDrawer(true) 
                                }} >상품수정</Button>
                        </Space>
                    </>
                )
            }
        },
    ];

    const endpoint = "/goodslist?t=un";
    const tableWidth = 1400;
    const drawerWidth = 600;

    useEffect(() => {
        getDataList(endpoint, setData, setLoading);
    }, [])

    const goodsInfo = (record) => {
        Modal.info({
            title: '상품 상제 정보 보기',
            style: {borderRadius: 10},
            content: (
                <div style={{padding:20}}>
                    <Table basic='very' celled collapsing style={{alignItems:'center', width:'100%'}}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell style={{width:120, textAlign:'center'}}>제품명</Table.Cell>
                                <Table.Cell style={{width:120, textAlign:'left'}}>{record.goodsName}</Table.Cell>
                                <Table.Cell style={{width:120, textAlign:'center'}}>제조사 / 공급사</Table.Cell>
                                <Table.Cell style={{width:120, textAlign:'left'}}>{record.companyName}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell style={{width:120, textAlign:'center'}}>구분</Table.Cell>
                                <Table.Cell style={{width:120, textAlign:'left'}}>{record.category}</Table.Cell>
                                <Table.Cell style={{width:120, textAlign:'center'}}>판매구분</Table.Cell>
                                <Table.Cell style={{width:120, textAlign:'left'}}>{record.classification}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell style={{width:120, textAlign:'center'}}>용량</Table.Cell>
                                <Table.Cell style={{width:120, textAlign:'left'}}>{record.volume} ml</Table.Cell>
                                <Table.Cell style={{width:120, textAlign:'center'}}>도수</Table.Cell>
                                <Table.Cell style={{width:120, textAlign:'left'}}>{record.alcoholRate} 도</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell style={{width:120, textAlign:'center'}}>상품상태</Table.Cell>
                                <Table.Cell colSpan='3' style={{width:120, textAlign:'left'}}>{record.goodsStatus === 'Y' ? '사용' : '사용불가'}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell style={{width:120, textAlign:'center'}}>상품 이미지</Table.Cell>
                                <Table.Cell colSpan='3'  style={{width:120, textAlign:'left'}}>{record.imgFilename}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell style={{width:120, textAlign:'center'}}>상품설명</Table.Cell>
                                <Table.Cell colSpan='3'  style={{width:120, textAlign:'left'}}>{record.description}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>          
                </div>
            ),
            width: 800,
            onOk() {},
        });
    }

    return (
        <div className="UnregisteredImages" style={{width:'100%', display:'flex', justifyContent:'flex-start', alignItems: 'center', flexDirection: 'column'}} >
            <div style={{display:'flex', justifyContent: 'start', marginBottom: 20, width:tableWidth}}>
                <Button primary size="medium" color='teal' onClick={() => {setCurrentRecord(null); setDrawerTitle('신제품(상품) 등록'); setMode('Add'); setOpenDrawer(true)}}>신제품 등록</Button><br />
            </div>

            <Table2 locale={locale} bordered dataSource={data} columns={columns} loading={loading} pagination={{ pageSize: 10, showSizeChanger: false }} 
                   size='small' scroll={{ x: true, y: 520 }} style={{width : tableWidth}}
                   onRow={(record) => {
                        return {
                            onClick : () => { setCurrentRecord(record); goodsInfo(record); }
                        }
                   }} 
            />

            <Drawer className="registerImage" title={drawerTitle} open={openDrawer} onClose={(e) => { e.stopPropagation(); setOpenDrawer(false)}} placement='right' width={drawerWidth} >
                { mode === 'Upload' && <GoodsImgUpload closeDrawer={setOpenDrawer} record={currentRecord} /> }
                { mode === 'Add' && <GoodsNewAdd /> }
                { mode === 'Update' && <GoodsUpdate /> }
            </Drawer>

        </div>
     );
}
 
export default UnregisteredImages;