/******************************************************************
 * 
 *   baccus LayOut 모듈 (화면의 Layout을 결정한다)
 *   2022.10.01 ~ 2022.10.12
 *   
 *   Copyright 2022 by YoungBin KO.
 * 
 * ****************************************************************/

 import React, { useEffect, useState } from 'react';
 import { Outlet, Link, useNavigate } from 'react-router-dom';
 
 import { Layout, Menu, Avatar, Dropdown, Divider } from 'antd';
 import { HomeFilled } from '@ant-design/icons';
 import 'antd/dist/antd.css';
 
 import { useRecoilState } from 'recoil';
 import { loginState, userState } from '../store/user-store';
 

 function ScreenLayout() {

    const [ isLogin, setLoginState ] = useRecoilState(loginState);
    const [ { userId, userName }, setUserState ] = useRecoilState(userState); 

    const { SubMenu } = Menu;
    const { Header, Footer, Content, Sider } = Layout;

    const profileMenu = (
        <Menu>
            <Menu.Item key="98"><Link to="/myinfo">내 정보 보기</Link></Menu.Item>
            <Menu.Divider />
            <Menu.Item key="99"><Link to="/logout">로그아웃</Link></Menu.Item>
        </Menu>
    );

    let savedIndex = sessionStorage.getItem('menuIndex') ? sessionStorage.getItem('menuIndex'): '1';

    useEffect(() => {
        if(savedIndex == null) {
            savedIndex = '1';
        }
    }, [])

    useEffect(() => {
        if(userName) console.log(userName);
    }, [userName])

    const menuItems = [
//        { label : <Link to="/">홈</Link>, key: '1', icon: <HomeFilled /> },
        { label : <> &nbsp; <Link to="/">홈</Link></>, key: '1', icon: <><img src='baccus32.png' /><img src='baccus_logo2_32.png' /></> },
        { label : <Link to="/notices">공지사항 관리</Link>, key: '2' }, 
        { label : <Link to="/wholesalers">도매장 관리</Link>, key: '3' }, 
        { label : <Link to="/members">시스템사용자 관리</Link>, key: '4' }, 
        { label : <Link to="/members2">일반 사용자 관리</Link>, key: '5' }, 
        { label : <Link to="/goodsimage" state={{setKey:'2-2-1'}}>상품이미지 관리</Link>, key: '6' }, 
        { label : <Link to="/community">자료실 / 커뮤니티</Link>, key: '7' }, 
    ];

    const clickHandler = (e) => {
        console.log('메뉴키', e.key)
        sessionStorage.setItem('menuIndex', e.key);
    }

    const handleSelect = (e) => {
        console.log('메뉴키', e.key)
        sessionStorage.setItem('menuIndex', e.key);
    }
        
     return (
        <Layout style={{minHeight: '100vh'}}>
            <Header className="header"  style={{ position: 'fixed', zIndex: 1, width: '100%', backgroundColor: '#fff'}}>
                <div style={{float:'right'}}>
                <div style={{float:'left'}}>{userName}님 환영합니다! &nbsp;&nbsp;</div>
                    <Dropdown overlay={profileMenu} placement="bottomRight" arrow overlayStyle={{margin: '-10px -10px 0px 0'}}>
                        <Avatar 
                            //src={imgSrc} 
                            size={40} style={{ margin: '10px 24px 16px 0', cursor: 'pointer', backgroundColor: '#fa8c16' }} >{userName}</Avatar>
                    </Dropdown>
                </div>

                {/* <Menu theme="white" mode="horizontal" onClick={clickHandler} onSelect={handleSelect} selectable={true} defaultSelectedKeys={savedIndex} items={menuItems} /> */}
                <Menu theme="white" mode="horizontal" onClick={clickHandler} onSelect={handleSelect} selectable={true} selectedKeys={savedIndex} items={menuItems} />
            </Header>
            <Layout>
                <Content style={{ padding: '0 20px', marginTop: 64}}>
                    <div className="main-panel" style={{ backgroundColor: '#fff', padding: 12, marginTop: 24, borderRadius: 10,
                                                         minHeight: '82vh', display:'flex', justifyContent:'center', alignItems: 'start' }}>
                        <Outlet />
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>(유)일신물산 ©2022 Created & Copyright by 고영빈</Footer>
            </Layout>
        </Layout>
     );
 
 }
 export default ScreenLayout;