import { apiCall } from "./custom-axios";

/** 
 *  antd Table에 필요한 데이터를 받아오는 공용 함수
 *  @endpoint api url
 *  @setData Data 값을 저장할 const 배열변수의 레퍼런스 함수명
 *  @setLoading true, false에 의한 loading Spinner 레퍼선스 함수명
 *  @retMessage 메세지 출력이 필요할때 메세지 String을 넘겨서 메세지를 표출할 수 있다.
 */
const getDataList = async ( endpoint, setData, setLoading, retMessage = null ) => {

    setLoading(true);
    console.log(endpoint)
    const res = await apiCall.get(endpoint);

    console.log(res.data)
    setData(res.data);
    setLoading(false);
}
export default getDataList;