/******************************************************************
 * 
 *   baccus Authorazation checking 컴포넌트가 불려올때 Auth 허용을 체크한다.
 *   2022.10.01 ~ 2022.10.12
 *   
 *   Copyright 2022 by YoungBin KO.
 * 
 * ****************************************************************/

 import React, { useEffect, useState } from 'react';
 //import { useNavigate } from 'react-router-dom';
 import { useNavigate, useLocation } from 'react-router-dom';
 
 import { useRecoilState } from 'recoil';
 import { loginState, userState } from '../store/user-store';
 
 import { apiCall } from '../common-lib/custom-axios';
 /*-------------------
    각 페이지에 대한 접근 권한을 설정한다.
    login 상태일때만 접근할수 있는 페이지... 그렇지 않은 페이지를 나눌때 사용한다.
    session 만료에 관한것은 여기에서 처리하지 않는다.
   ------------------*/
 function AuthCheck(props) {
 
     const [ { isLogin, isSession }, setLoginState ] = useRecoilState(loginState);
     const [ { userId, userName, shopName, shopCode, shopNumber }, setUserState ] = useRecoilState(userState);
 
     const SSID = sessionStorage.getItem('SSID');
 
     let location = useLocation();
     let navigate = useNavigate();
 
     useEffect(() => {
 
         // 로그인한 상태, 로그인하지 않은 상태 => isLogin으로
         // 세션이 만료된 상태 => is-session으로 체크
         // 로그인 하지 않은 상태 - isLogin : false, SSID : null
         if(!isLogin && !SSID) {
             navigate('/login');
         }
         else if(!isLogin && SSID) {
             // F5가 눌린상태
             sessionStorage.setItem('menuIndex', 1);
             resetLogin();
         }
         else if(isLogin && SSID) {
             // 로그인한 상태
             // isLogin : true, SSID : 값이 있음.
             navigate('/');
         }
 
     }, [SSID, isLogin]);
 
     const resetLogin = async () => {
         // 동기화된 상태로 셋팅값을 변경하려면... return 값으로 받아서 처리하는것보다 동기화된 함수내에서 처리하는 것이 낫다.
         await apiCall.get('/is-session').then( res =>{
             if(res.data.loginSuccess) {
                 setUserState({ userId : res.data.userId, userName : res.data.userName, shopCode: res.data.shopCode, shopName: res.data.shopName,
                                shopNumber : res.data.shopNumber });
                 setLoginState({isLogin: true});
                 navigate('/');
                 console.log('로그인 된 상태')
             }
             else {
                 sessionStorage.clear();
                 // session 관련 메세지를 줘야한다.
                 navigate('/login');
                 console.log('로그인 되지 않은 상태')
             }
         });
     }
 
     if(props.loginState === isLogin)
         return <>{props.children}</>;
 
 }
 export default AuthCheck;