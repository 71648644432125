//import logo from './logo.svg';
import React, { useEffect } from 'react';
import { useRoutes, useNavigate, Outlet } from 'react-router-dom';

import Auth from './auth/AuthCheck';
import NotFound from './pages/404';
import ScreenLayout from './pages/Layout';
import Home from './pages/Home';

import Login from './pages/Login';
import Logout from './pages/Logout';
import Notices from './pages/Notices';
import Members from './pages/Members';
import Members2 from './pages/Members2';
import Wholesalers from './pages/Wholesalers';
import GoodsImage from './pages/GoodsImage';

import './App.css';
import "antd/dist/antd.css";

function App() {

  let navigate = useNavigate();

  const SSID = sessionStorage.getItem('SSID');

  // user의 등급에 따라서 routes를 따로 구성한다.
  
  const routes = [
    {
      element : <Auth loginState={true}><ScreenLayout /></Auth>,
      path : '/',
      children : [
        { index: true, element : <Auth loginState={true}><Home /></Auth> },        
        { element : <Auth loginState={true}><Notices /></Auth>, path : '/notices' },
        { element : <Auth loginState={true}><Wholesalers /></Auth>, path : '/wholesalers' },
        { element : <Auth loginState={true}><Members /></Auth>, path : '/members' },
        { element : <Auth loginState={true}><Members2 /></Auth>, path : '/members2' },
        { element : <Auth loginState={true}><GoodsImage /></Auth>, path : '/goodsimage' },
        { element : <Auth loginState={true}><Logout /></Auth>, path : '/logout' },
      ],
    },
    { element : <Auth loginState={false}><Login /></Auth>, path : '/login' },
    { element : <NotFound />, path : '*' },
  ]

  const element = useRoutes(routes);

  return (     
    <div className="App">
      { element }
    </div>
  );
}
export default App;