import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { loginState, userState } from '../store/user-store';

function Logout() {

    const navigate = useNavigate();
    const [ isLogin, setLoginState ] = useRecoilState(loginState);
    const [ { userId, userName }, setUserState ] = useRecoilState(userState); 

    useEffect(() => {

        sessionStorage.clear();

        // SESSION ID도 삭제해준다.
        setLoginState({ isLogin: false});

        navigate('/', {replace: true});
        
    }, [])

    return null;
}

export default Logout;