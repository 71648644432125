/******************************************************************
 * 
 *   baccus common library - Custom AXIOS
 *   2022.10.01 ~ 2022.10.12
 *   
 *   Copyright 2022 by YoungBin KO.
 * 
 * ****************************************************************/

 import axios from 'axios';
 import { AXIOS_HEADERS, ADMIN_WEB_URL, BACCUS_URL, IMAGES_URL } from '../config/config';
 
 export const apiLogin = axios.create({
     baseURL : `${ADMIN_WEB_URL}`,
     headers : AXIOS_HEADERS,
 });
 
 export const apiCall = axios.create({
     baseURL : `${ADMIN_WEB_URL}`,
     headers : AXIOS_HEADERS,
 });
 
 export const baccusApiCall = axios.create({
     baseURL : `${BACCUS_URL}`,
     headers : AXIOS_HEADERS,
 });
 
 export const imageApiCall = axios.create({
     baseURL : `${IMAGES_URL}`,
     headers : AXIOS_HEADERS,
 });
 
 // Custom axios apiCall이 호출되면 SSID를 Bearer Authorization으로 설정한다.
 apiCall.interceptors.request.use( async function (config) {
 
     const SSID = sessionStorage.getItem('SSID');
     config.headers.Authorization = `Bearer ${SSID}`;
 
     return config;
 });
 
 