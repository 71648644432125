import React from 'react';


function GoodsUpdate() {

    return(
        <>
        </>
    );
}
export default GoodsUpdate;