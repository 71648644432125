/******************************************************************
 * 
 *   baccus Home 모듈
 *   2022.11.20 ~ 2022.11.23
 *   
 *   Copyright 2022 by YoungBin KO.
 * 
 * ****************************************************************/

 import React, { useEffect, useLayoutEffect, useState } from 'react';
 import { Divider } from 'semantic-ui-react';
 
 import { apiCall } from '../common-lib/custom-axios';
  
 import { useRecoilState } from 'recoil';
 import { loginState, userState } from '../store/user-store';

 function Home() {
  
 
     return (
         <div className="Home" style={{ width:1660 }} >
            <div style={{display: 'flex', justifyContent:'center', alignItems: 'end', marginTop: 50}}>
               <img src="baccus512.png" width="512" height="512" />
               <div style={{color:'#666', fontSize:48, fontWeight: 'bold', marginBottom: 20}}>TO</div>
               <img src="baccus_logo2_512.png" width="512" height="512" />
            </div>
            <center style={{marginTop:50}}>
               <Divider style={{width:530}} />
               <div style={{fontSize:'20px', fontWeight:'bold', color: '#666666'}}>주류전문 모바일 주문 & 실시간 업무 관리 & 경영정보 시스템</div>
               <Divider style={{width:530}} />
            </center>
         </div>
      );
 }
  
 export default Home;