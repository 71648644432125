/******************************************************************
 * 
 *   baccus System 공지사항 모듈
 *   2022.11.22 ~ 2022.
 *   
 *   Copyright 2022 by YoungBin KO.
 * 
 * ****************************************************************/
 import React, { useEffect, useState } from 'react';
 
 import { Table, Button, Drawer } from 'antd';
 import { apiCall } from '../common-lib/custom-axios';

 import { Grid, Segment, Divider, Button as Button2 } from 'semantic-ui-react';

 import '../css/colorTable.css';
 import '../css/colorDrawer.css';

 const columns = [
    {
        title: '회사명',
        dataIndex: 'corporateName',
        key: 'corporateName',
        width: 250,
        align: 'center'
    },
    {
        title: '지역',
        dataIndex: 'area',
        key: 'area',
        width: 100,
        align: 'center'
    },
    {
        title: '회사 연락처',
        dataIndex: 'corporatePhone',
        key: 'corporatePhone',
        width: 120,
        align: 'center'
    },
    {
        title: '대표자',
        dataIndex: 'representativeName',
        key: 'representativeName',
        width: 120,
        align: 'center',
    },
    {
        title: '대표 연락처',
        dataIndex: 'representativePhone',
        key: 'representativePhone',
        width: 130,
        align: 'center',
    },
    {
        title: '사업자번호',
        dataIndex: 'businessNumber',
        key: 'businessNumber',
        width: 160,
        align: 'center',
    },
    {
        title: '전산담당자',
        dataIndex: 'systemDirectorName',
        key: 'systemDirectorName',
        width: 120,
        align: 'center',
    },
    {
        title: '전산담당 연락처',
        dataIndex: 'systemDirectorPhone',
        key: 'systemDirectorPhone',
        width: 130,
        align: 'center',
    },
    {
        title: '바쿠스 사용등급',
        dataIndex: 'baccusGrade',
        key: 'baccusGrade',
        width: 120,
        align:'center'
    },
    {
        title: '사용중 ERP',
        dataIndex: 'erpSystemType',
        key: 'erpSystemType',
        width: 120,
        align:'center'
    },
 ];

 function Wholesalers() {

    const [ data, setData ] = useState([]);
    const [ showDrawer, setShowDrawer ] = useState(false);
    const [ drawerTitle, setDrawerTitle ] = useState('도매장 정보 보기');
    const [ drawerMode, setDrawerMode ] = useState('View');
    const [ currentRecdord, setCurrentRecord ] = useState({});

     useEffect(() => {
        getWholesalers();
     }, [])

     const getWholesalers = async (id) => {
         const res = await apiCall.get('/wholesalers');
         
         console.log(res.data)
         setData(res.data);
     }

     const drawerCloseHandler = () => {
        setShowDrawer(false);
     }

     const handleOnRow = (values) => {
        setDrawerMode('View');
        setShowDrawer(true);
        setCurrentRecord(values);
     }

     const newWholesalers = () => {
        setDrawerMode('Add');
        setShowDrawer(true);
     }
    
     const noticeView = (
        <>
        <div className='wholesalersView'>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16} style={{textAlign:'center'}}>
                        <img src={`http://clients.baccus.co.kr/${currentRecdord.imageName}`} style={{borderRadius:10}} />
                    </Grid.Column>
                </Grid.Row>
                <Divider style={{marginTop:5, marginBottom:5}} />
                <Grid.Row>
                    <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>공지대상</Segment></Grid.Column>
                    <Grid.Column width={5}><Segment >{currentRecdord.targetUser}</Segment></Grid.Column>
                    <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>파일명</Segment></Grid.Column>
                    <Grid.Column width={5}><Segment >{currentRecdord.imageName}</Segment></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>작성일</Segment></Grid.Column>
                    <Grid.Column width={5}><Segment >{currentRecdord.nDate}</Segment></Grid.Column>
                    <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>작성자</Segment></Grid.Column>
                    <Grid.Column width={5}><Segment >{currentRecdord.writer}</Segment></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>공지제목</Segment></Grid.Column>
                    <Grid.Column width={13}><Segment >{currentRecdord.title}</Segment></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>공지내용</Segment></Grid.Column>
                    <Grid.Column width={13}><Segment ><div style={{whiteSpace: 'pre-wrap', textAlign: 'justify'}}>{currentRecdord.contents}</div></Segment></Grid.Column>
                </Grid.Row>
            </Grid>

            <Divider style={{marginTop:40, marginBottom:40}} />
            <center>
                <Button2 onClick={drawerCloseHandler} basic>취소</Button2> &nbsp; &nbsp; &nbsp; <Button2 negative >수정하기</Button2>
            </center>
        </div>
        </>
     );

     const noticeAdd = (
        <>
        <div className='wholesalersAdd'>
            
        </div>
        </>
     );

     const noticeEdit = (
        <>
        </>
     );

     return (
         <div className="wholesalers">
            <div style={{display:'flex', justifyContent: 'start', marginTop: 60}}>
                <Button2 primary size='large' onClick={newWholesalers}>도매장 등록</Button2>
            </div>
            <p />
            <Table dataSource={data} columns={columns} pagination={{ pageSize: 5 }} size='small' 
                   onRow={(record) => {
                        return {
                            onClick : () => {handleOnRow(record)}
                        }
                   }} />

            <Drawer className="wholesalersDrawer" title={drawerTitle} visible={showDrawer} onClose={drawerCloseHandler}
                    placement='right' width={720} >
                    {/* {drawerMode === 'view' ? DrawerViewNotice : DrawerAddEditNotice } */}
                    { drawerMode === 'View' && noticeView }
                    { drawerMode === 'Add'  && noticeAdd }
                    { drawerMode === 'Edit' && noticeEdit }
            </Drawer>

         </div>
     );
 }
 
 export default Wholesalers;