import React, { useRef, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Input, Form, Button, Checkbox } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { Modal, Header, Icon, Button as Button2 } from 'semantic-ui-react';

import { useRecoilState } from 'recoil';
import { loginState, userState } from '../store/user-store';
import { apiLogin } from '../common-lib/custom-axios';
//import Cookies from 'universal-cookie';

function Login() {

    const userInput = useRef();
    let navigate = useNavigate();

    useLayoutEffect(() => {
        if(userInput.current !== null) userInput.current.focus();
    })

    const [ isLogin, setLoginState ] = useRecoilState(loginState);
    const [ { userId, userName }, setUserState ] = useRecoilState(userState); 
    const [ onOpen, setOnOpen ] = useState(false);

    const onFinish = async (values) => {

        console.log(values)

        const res = await apiLogin.post('/login', values);

        if(res.status === 200 && res.statusText === "OK") {
            // api 통신 성공
            console.log('api 통신 성공 : ', res);
            console.log('data : ', res.data);

            if(res.data.loginSuccess) {

                // 로그인에 성공하면~ recoil에 정보를 담아준다.
                sessionStorage.setItem('SSID', res.data.SSID)

                setLoginState({ isLogin: true})
                setUserState({ userId : res.data.userId, userName : res.data.userName});
                console.log(res.data.loginSuccess)
                navigate('/');

            }
            else {
                setOnOpen(prevState => !prevState);
            }
        }
        else {
            // api 통신 에러
        }
    }

    const onFinishFailed = () => {}

    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    return (
        <div className="login" style={{ display:'flex', width:'100%', height:'100vh', justifyContent:'center', alignItems: 'center', 
                                        backgroundColor:'#efefef', backgroundImage : 'url(/cool-background.png)', backgroundSize: 'cover' }}>

            <div className="loginCard" style={{ display:'flex', flexDirection: 'column', justifyContent:'flex-start', width:500, height: 360, border : '1px solid #173676', borderTopLeftRadius: 10, borderBottomLeftRadius: 10, backgroundColor: '#173676'}}>
                <div style={{display: 'flex', justifyContent:'flex-start', marginLeft: 40, marginTop: 20, fontSize: 24, fontWeight: 'bold', color: '#FFFFFF'}}>Welcome to Baccus</div>
                {/* <hr style={{display: 'flex', justifyContent:'flex-start', marginLeft: 40, fontSize: 18, fontWeight: 'bold', color: '#FFFFFF'}} width="160px" /> */}
                <div style={{display: 'flex', justifyContent:'center', alignItems: 'end', marginTop: 50}}>
                    <img src="baccus192.png" width="192" height="192" />
                    <div style={{color:'#fff', fontSize:32, fontWeight: 'bold', marginBottom: 20}}>TO</div>
                    <img src="baccus_logo2_192.png" width="192" height="192" />
                </div>
            </div>
            <div className="loginCard" style={{ width:400, height: 360, border : '1px solid #cfcfcf', borderTopRightRadius: 10, borderBottomRightRadius: 10, backgroundColor: '#ffffff'}}>
                <div style={{marginTop:50, marginBottom:10, fontSize: 18, fontWeight: 'bold'}}>바쿠스 시스템 관리자</div>
                <Form className='loginForm'
                      name="basic"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 14 }}
                      initialValues={{ remember: true }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off" style={{marginTop:40}}>
                    <Form.Item
                        label="아이디"
                        name="email"
                        rules={[{ required: true, message: '반드시 입력해주세요' }]} >
                        <Input placeholder="이메일 주소"
                           suffix={<UserOutlined className="site-form-item-icon" style={{color:'#aaa'}} />}
                           ref={userInput} />
                    </Form.Item>
                    <Form.Item
                        label="비밀번호"
                        name="userpass"
                        rules={[{ required: true, message: '반드시 입력해주세요' }]} >
                        <Input.Password placeholder="비밀번호" />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 4, span: 18 }}>
                        <Checkbox onChange={onChange} >로그인 정보 기억하기</Checkbox>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 4, span: 18 }}>
                        <Button type="primary" htmlType="submit"  style={{borderRadius:20, color:'white', width:120}}>
                            로그인
                        </Button>
                        &nbsp; &nbsp;
                        <Button style={{borderRadius:20, width:100}}>
                            취소
                        </Button>
                    </Form.Item>

                </Form>
            </div>


            <Modal 
                    open={onOpen}
                    onClose={() => setOnOpen(false)}
                    onOpen={() => setOnOpen(true)}
                    style={{ width: 420}}>
                <Header icon='times' content='로그인 에러' />
                <Modal.Content>
                    <p>
                    아이디나 패스워드가 맞지 않아 로그인 할 수 없습니다.<br /> 다시 확인해주세요.
                    </p>
                </Modal.Content>
                <Modal.Actions>
                <Button2 color='green' onClick={() => setOnOpen(false)}>
                    <Icon name='checkmark' /> 확인
                </Button2>
                </Modal.Actions>
            </Modal>

        </div>
    );
}
export default Login;