/******************************************************************
 * 
 *   baccus System 공지사항 모듈
 *   2022.11.22 ~ 2022.
 *   
 *   Copyright 2022 by YoungBin KO.
 * 
 * ****************************************************************/
 import React, { useEffect, useState } from 'react';
 import { useForm, Controller, set } from 'react-hook-form';
 
 import { Table, Button, Drawer } from 'antd';
 import { apiCall } from '../common-lib/custom-axios';

 import { Grid, Segment, Divider, Button as Button2, Dropdown } from 'semantic-ui-react';

 import '../css/colorTable.css';
 import '../css/colorDrawer.css';
 
 const columns = [
    {
      title: '아이디',
      dataIndex: 'userId',
      key: 'userId',
      width: 200,
      align: 'center'
    },
    {
        title: '이름',
        dataIndex: 'userName',
        key: 'userName',
        width: 80,
        align: 'center'
    },
    {
        title: '연락처',
        dataIndex: 'phone',
        key: 'phone',
        width: 140,
        align: 'center'
    },
    {
        title: '법인전화',
        dataIndex: 'businessPhone',
        key: 'businessPhone',
        width: 140,
        align: 'center'
    },
    {
        title: '접근권한',
        dataIndex: 'accessLevel',
        key: 'accessLevel',
        width: 80,
        align: 'center',
    },
    {
        title: '관리구역',
        dataIndex: 'businessDepartment',
        key: 'businessDepartment',
        width: 120,
        align: 'center',
    },
    {
        title: '도매장명',
        dataIndex: 'wholesalerName',
        key: 'wholesalerName',
        width: 160,
        align: 'center',
    },
    {
        title: '관리자 유무',
        dataIndex: 'isWholesalerAdmin',
        key: 'isWholesalerAdmin',
        width: 160,
        align: 'center',
    },
    {
        title: '가입일',
        dataIndex: 'joinDate',
        key: 'joinDate',
        width: 160,
        align: 'center',
    },
    {
        title: '해제일',
        dataIndex: 'closeDate',
        key: 'closeDate',
        width: 160,
        align: 'center',
    },
    {
        title: '삭제유무',
        dataIndex: 'delField',
        key: 'delField',
        width: 160,
        align: 'center',
    },
];

 function Members2() {

     const [ data, setData ] = useState([]);
     const [ regionalDivision, setRegionalDivision ] = useState([]);
     const [ wholesalers, setWholesalers ] = useState([]);
     const [ showDrawer, setShowDrawer ] = useState(false);
     const [ drawerTitle, setDrawerTitle ] = useState('도매장 일반 사용자 보기');
     const [ drawerMode, setDrawerMode ] = useState('View');
     const [ currentRecdord, setCurrentRecord ] = useState({});

     const { register, setValue, getValues, handleSubmit, rest, control, formState: { errors } } = useForm();

     useEffect(() => {
        getMembers2();
        getRegionalDivision();
     }, [])

     const getMembers2 = async (id) => {
         const res = await apiCall.get('/members2');
         
         console.log(res.data)
         setData(res.data);
     }

     const getRegionalDivision = async () => {
        const res = await apiCall.get('/regionaldivision');

        console.log(res.data);
        setRegionalDivision(res.data);
     }

     const drawerCloseHandler = () => {
        setShowDrawer(false);
     }

     const handleOnRow = (values) => {
        setShowDrawer(true);
        setCurrentRecord(values);
     }
    
     const members2View = (
        <>
        <div className='membersView'>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16} style={{textAlign:'center'}}>
                        <img src={`http://clients.baccus.co.kr/${currentRecdord.imageName}`} style={{borderRadius:10}} />
                    </Grid.Column>
                </Grid.Row>
                <Divider style={{marginTop:5, marginBottom:5}} />
                <Grid.Row>
                    <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>공지대상</Segment></Grid.Column>
                    <Grid.Column width={5}><Segment >{currentRecdord.targetUser}</Segment></Grid.Column>
                    <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>파일명</Segment></Grid.Column>
                    <Grid.Column width={5}><Segment >{currentRecdord.imageName}</Segment></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={5}></Grid.Column>
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={5}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>공지제목</Segment></Grid.Column>
                    <Grid.Column width={13}><Segment >{currentRecdord.title}</Segment></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>공지내용</Segment></Grid.Column>
                    <Grid.Column width={13}><Segment ><div style={{whiteSpace: 'pre-wrap', textAlign: 'justify'}}>{currentRecdord.contents}</div></Segment></Grid.Column>
                </Grid.Row>
            </Grid>

            <Divider style={{marginTop:40, marginBottom:40}} />
            <center>
                <Button2 onClick={drawerCloseHandler} basic>취소</Button2> &nbsp; &nbsp; &nbsp; <Button2 negative >수정하기</Button2>
            </center>
        </div>
        </>
     );

     const members2Add = (
        <>
        </>
     );

     const members2Edit = (
        <>
        </>
     );

     const onChangeSelect = async (e, value)  => {
        console.log(value);
        const res = await apiCall.get(`/wholesalers?ar=${value}`);
        console.log(res.data)
        setWholesalers(res.data);
     }
     
     const onChangeSelect2 = async (e, value)  => {
        console.log(value);
        const res = await apiCall.get(`/members2?wn=${value}`);
        console.log(res.data);
        setData(res.data);
     }

     return (
         <div className="Members">
            <div style={{display:'flex', justifyContent: 'space-between', marginTop: 60, alignItems:'end'}}>
                <div><Button2 primary size='large'>새 일반도매장 사용자 등록</Button2></div>
                <div>
                    <Dropdown clearable selection search placeholder='지역선택' options={regionalDivision} onChange={(e, {value}) => onChangeSelect(e, value)} />
                     &nbsp;
                    <Dropdown clearable selection search placeholder='도매장 선택' options={wholesalers} onChange={(e, {value}) => onChangeSelect2(e, value)} />
                </div>
            </div>
            <p />
            <Table dataSource={data} columns={columns} pagination={{ pageSize: 5 }} size='small' 
                   onRow={(record) => {
                        return {
                            onClick : () => {handleOnRow(record)}
                        }
                   }} />

            <Drawer className="membersDrawer" title={drawerTitle} visible={showDrawer} onClose={drawerCloseHandler}
                    placement='right' width={720} >
                    {/* {drawerMode === 'view' ? DrawerViewNotice : DrawerAddEditNotice } */}
                    { drawerMode === 'View' && members2View }
                    { drawerMode === 'Add'  && members2Add }
                    { drawerMode === 'Edit' && members2Edit }
            </Drawer>

         </div>
     );
 }
 
 export default Members2;