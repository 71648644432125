/******************************************************************
 * 
 *   baccus System 상품이미지 등록
 *   2022.11.22 ~ 2022.
 *   
 *   Copyright 2022 by YoungBin KO.
 * 
 * ****************************************************************/
 import React, { useEffect, useState } from 'react';
 import { useLocation } from 'react-router-dom';

 import { Table, Button, Drawer, Tabs } from 'antd';
 import { apiCall } from '../common-lib/custom-axios';

 import { Grid, Segment, Divider, Button as Button2 } from 'semantic-ui-react';

 import '../css/colorTable.css';
 import '../css/colorDrawer.css';
 import '../css/tabsColor.css';

 import UnregisteredImages from './subpages/UnregisteredImages';
 import RegisteredImages from './subpages/RegisteredImages';

 function GoodsImage() {

    const location = useLocation();
    const { setKey } = location.state;
    const [ currentKey, setCurrentKey ] = useState('');
 
    useEffect(()=> {
       setCurrentKey(setKey);
    }, [setKey])
 
     const items = [
         { label: '이미지 등록상품', key: '2-2-1', children: <RegisteredImages /> },
         { label: '이미지 미등록상품', key: '2-2-2', children: <UnregisteredImages /> }, 
     ];
 
     const handleChange = (key) => {
       setCurrentKey(key)
     }
 
      return (
          <div className="GoodsImage" style={{width:'100%', padding: 20}}>
             <Tabs type="card" tabPosition="left" items={items} activeKey={currentKey} onChange={handleChange} />
          </div>
       );
}
 
export default GoodsImage;