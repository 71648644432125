/******************************************************************
 * 
 *   baccus System 공지사항 모듈
 *   2022.11.22 ~ 2022.
 *   
 *   Copyright 2022 by YoungBin KO.
 * 
 * ****************************************************************/
 import React, { useEffect, useState } from 'react';
 import { useForm } from 'react-hook-form';
 
 import { Table, Button, Drawer } from 'antd';
 import { apiCall } from '../common-lib/custom-axios';

 import { Grid, Segment, Divider, Button as Button2 } from 'semantic-ui-react';

 import '../css/colorTable.css';
 import '../css/colorDrawer.css';
 
 const columns = [
    {
        title: '아이디',
        dataIndex: 'userId',
        key: 'userId',
        width: 240,
        align: 'center'
    },
    {
        title: '이름',
        dataIndex: 'userName',
        key: 'userName',
        width: 220,
        align: 'center'
    },
    {
        title: '전화번호',
        dataIndex: 'phone',
        key: 'phone',
        width: 220,
        align: 'center',
    },
];

 function Members() {

    const [ data, setData ] = useState([]);
    const [ showDrawer, setShowDrawer ] = useState(false);
    const [ drawerTitle, setDrawerTitle ] = useState('공지사항 보기');
    const [ drawerMode, setDrawerMode ] = useState('View');
    const [ currentRecdord, setCurrentRecord ] = useState({});

    const { register, handleSubmit, watch, formState: { errors } } = useForm();

 
 
     useEffect(() => {
        getMembers();
     }, [])

     const getMembers = async (id) => {
         const res = await apiCall.get('/members');
         
         console.log(res.data)
         setData(res.data);
     }

     const drawerCloseHandler = () => {
        setShowDrawer(false);
     }

     const handleOnRow = (values) => {
        setShowDrawer(true);
        setCurrentRecord(values);
     }
    
     const noticeView = (
        <>
        <div className='membersView'>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16} style={{textAlign:'center'}}>
                        <img src={`http://clients.baccus.co.kr/${currentRecdord.imageName}`} style={{borderRadius:10}} />
                    </Grid.Column>
                </Grid.Row>
                <Divider style={{marginTop:5, marginBottom:5}} />
                <Grid.Row>
                    <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>공지대상</Segment></Grid.Column>
                    <Grid.Column width={5}><Segment >{currentRecdord.targetUser}</Segment></Grid.Column>
                    <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>파일명</Segment></Grid.Column>
                    <Grid.Column width={5}><Segment >{currentRecdord.imageName}</Segment></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={5}></Grid.Column>
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={5}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>공지제목</Segment></Grid.Column>
                    <Grid.Column width={13}><Segment >{currentRecdord.title}</Segment></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>공지내용</Segment></Grid.Column>
                    <Grid.Column width={13}><Segment ><div style={{whiteSpace: 'pre-wrap', textAlign: 'justify'}}>{currentRecdord.contents}</div></Segment></Grid.Column>
                </Grid.Row>
            </Grid>

            <Divider style={{marginTop:40, marginBottom:40}} />
            <center>
                <Button2 onClick={drawerCloseHandler} basic>취소</Button2> &nbsp; &nbsp; &nbsp; <Button2 negative >수정하기</Button2>
            </center>
        </div>
        </>
     );

     const noticeAdd = (
        <>
        </>
     );

     const noticeEdit = (
        <>
        </>
     );

     return (
         <div className="Members">
            <div style={{display:'flex', justifyContent: 'start', marginTop: 60}}>
                <Button2 primary size='large'>새 시스템 사용자 등록</Button2>
            </div>
            <p />
            <Table dataSource={data} columns={columns} pagination={{ pageSize: 5 }} size='small' 
                   onRow={(record) => {
                        return {
                            onClick : () => {handleOnRow(record)}
                        }
                   }} />

            <Drawer className="membersDrawer" title={drawerTitle} visible={showDrawer} onClose={drawerCloseHandler}
                    placement='right' width={720} >
                    {/* {drawerMode === 'view' ? DrawerViewNotice : DrawerAddEditNotice } */}
                    { drawerMode === 'View' && noticeView }
                    { drawerMode === 'Add'  && noticeAdd }
                    { drawerMode === 'Edit' && noticeEdit }
            </Drawer>

         </div>
     );
 }
 
 export default Members;