import React, { useState, useEffect } from 'react';
import { Upload, Space, Button } from 'antd';
import { useForm, Controller, set } from 'react-hook-form';

import { PlusOutlined, SaveOutlined } from '@ant-design/icons';

import { apiCall, imageApiCall } from '../../../common-lib/custom-axios';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

function GoodsImgUpload(props) {

    const [ fileList, setFileList ] = useState([]);
    const [ previewVisible, setPreviewVisible ] = useState(false);
    const [ previewImage, setPreviewImage ] = useState('');
    const [ previewTitle, setPreviewTitle ] = useState('');
    const [ loading, setLoading ] = useState(false);

    const { register, setValue, handleSubmit, control, formState: {errors} } = useForm();

    useEffect(() => {
      setValue('imagesList', fileList);
    }, [fileList])

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
              style={{
                marginTop: 8,
              }}
            >
              Upload
            </div>
        </div>
    );

    const uploadConfig = {
        beforeUpload(file) {
          file.thumbUri = '';
          return file;
        }
    }

    const handlePreview = async (file) => {

        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
  
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    }

    const handleChange = (info) => {

        if(info.file.status === 'done') {
        }
          setFileList(info.fileList);
    }
  
    const drawerCancelHandler = () => {
      props.closeDrawer(false);
    }

    const onSubmit = async (data) => {
      setLoading(true);

      const res = await imageApiCall.post('/images/goods', {data}).then(res => res.data);
      const res2 = await apiCall.put('/uploadfilename', {imgFilename : res.changeName, id : props.record.id}).then(res => res.data);

      setLoading(false)
      props.closeDrawer(false);

      // 상품이미지가 등록됐음을 메세지로 보여주고 끝낸다.
    }

    return(
        <>
          <form onSubmit={handleSubmit(onSubmit)} className="uploading">
            <Upload {...uploadConfig} action="http://images.baccus.co.kr/uploadimages"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
            >
                {fileList.length < 1 && uploadButton}
            </Upload> 
            <input {...register('imagesList')} hidden />
          </form>
          <Space>
            <Button onClick={handleSubmit(onSubmit)} icon={<SaveOutlined />} type="primary" danger style={{borderRadius:20, color:'white', width:120}}>저&nbsp;&nbsp;장</Button>
            {/* 취소할때 tmp에 저장된 이미지도 지워주도록 한다. */}
            <Button onClick={drawerCancelHandler} style={{borderRadius:20}} >취소</Button>
          </Space>

        </>
    );
}
export default GoodsImgUpload;