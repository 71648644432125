/******************************************************************
 * 
 *   baccus System 공지사항 모듈
 *   2022.11.22 ~ 2022.
 *   
 *   Copyright 2022 by YoungBin KO.
 * 
 * ****************************************************************/
 import React, { useEffect, useState } from 'react';
 
 import { Table, Button, Drawer } from 'antd';
 import { apiCall } from '../common-lib/custom-axios';

 import { Grid, Segment, Divider, Button as Button2 } from 'semantic-ui-react';

 import '../css/colorTable.css';
 import '../css/colorDrawer.css';

 const columns = [
    {
      title: '공지일자',
      dataIndex: 'nDate',
      key: 'nDate',
      width: 120,
      align: 'center'
    },
    {
        title: '작성자',
        dataIndex: 'writer',
        key: 'writer',
        width: 200,
        align: 'center'
    },
    {
        title: '모바일 대표 이미지',
        dataIndex: 'imageName',
        key: 'imageName',
        width: 220,
        align: 'center',
        render: (text, idx) => {
            return (
                <>
                    <img src={`http://clients.baccus.co.kr/${text}`} width='160' />
                </>
            )
        }
    },
    {
        title: '공지제목',
        dataIndex: 'title',
        key: 'title',
        width: 300,
    },
    {
        title: '내 용',
        dataIndex: 'contents',
        key: 'contents',
        width: 800,
        render: (text) => {
            let text2;

            if (text.length < 220) {
                text2 = text.substr(0, 220);
            }
            else {
                text2 = text.substr(0, 220) + '... <계속>';
            }

            return (
                <>
                    {text2}
                </>
            )
        }
    },
    {
        title: '공지대상',
        dataIndex: 'targetUser',
        key: 'targetUser',
        width: 100,
        align:'center'
    },
 ];

 function Notices() {

    const [ data, setData ] = useState([]);
    const [ showDrawer, setShowDrawer ] = useState(false);
    const [ drawerTitle, setDrawerTitle ] = useState('공지사항 보기');
    const [ drawerMode, setDrawerMode ] = useState('View');
    const [ currentRecdord, setCurrentRecord ] = useState({});

     useEffect(() => {
        getNotice();
     }, [])

     const getNotice = async (id) => {
         const res = await apiCall.get('/notices');
         
         console.log(res.data)
         setData(res.data);
     }

     const drawerCloseHandler = () => {
        setShowDrawer(false);
     }

     const handleOnRow = (values) => {
        setDrawerMode('View');
        setShowDrawer(true);
        setCurrentRecord(values);
     }

     const newNotice = () => {
        setDrawerMode('Add');
        setDrawerTitle('새로운 공지사항 작성')
        setShowDrawer(true);
     }
    
     const noticeView = (
        <>
            <div className='noticeView'>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16} style={{textAlign:'center'}}>
                            <img src={`http://clients.baccus.co.kr/${currentRecdord.imageName}`} style={{borderRadius:10}} />
                        </Grid.Column>
                    </Grid.Row>
                    <Divider style={{marginTop:5, marginBottom:5}} />
                    <Grid.Row>
                        <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>공지대상</Segment></Grid.Column>
                        <Grid.Column width={5}><Segment >{currentRecdord.targetUser}</Segment></Grid.Column>
                        <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>파일명</Segment></Grid.Column>
                        <Grid.Column width={5}><Segment >{currentRecdord.imageName}</Segment></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>작성일</Segment></Grid.Column>
                        <Grid.Column width={5}><Segment >{currentRecdord.nDate}</Segment></Grid.Column>
                        <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>작성자</Segment></Grid.Column>
                        <Grid.Column width={5}><Segment >{currentRecdord.writer}</Segment></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>공지제목</Segment></Grid.Column>
                        <Grid.Column width={13}><Segment >{currentRecdord.title}</Segment></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}><Segment inverted color='green' textAlign='center'>공지내용</Segment></Grid.Column>
                        <Grid.Column width={13}><Segment ><div style={{whiteSpace: 'pre-wrap', textAlign: 'justify'}}>{currentRecdord.contents}</div></Segment></Grid.Column>
                    </Grid.Row>
                </Grid>

                <Divider style={{marginTop:40, marginBottom:40}} />
                <center>
                    <Button2 onClick={drawerCloseHandler} basic>취소</Button2> &nbsp; &nbsp; &nbsp; <Button2 negative >수정하기</Button2>
                </center>
            </div>
        </>
     );

     const noticeAdd = (
        <>
            <div className='noticeAdd'>
            
            </div>
        </>
     );

     const noticeEdit = (
        <>
            <div className='noticeEdit'>
            
            </div>
        </>
     );

     return (
         <div className="Notices">
            <div style={{display:'flex', justifyContent: 'start', marginTop: 60}}>
                <Button2 primary size='large' onClick={newNotice}>새 공지사항 작성</Button2>
            </div>
            <p />
            <Table dataSource={data} columns={columns} pagination={{ pageSize: 5 }} size='small' 
                   onRow={(record) => {
                        return {
                            onClick : () => {handleOnRow(record)}
                        }
                   }} />

            <Drawer className="noticesDrawer" title={drawerTitle} visible={showDrawer} onClose={drawerCloseHandler}
                    placement='right' width={720} >
                    {/* {drawerMode === 'view' ? DrawerViewNotice : DrawerAddEditNotice } */}
                    { drawerMode === 'View' && noticeView }
                    { drawerMode === 'Add'  && noticeAdd }
                    { drawerMode === 'Edit' && noticeEdit }
            </Drawer>

         </div>
     );
 }
 
 export default Notices;