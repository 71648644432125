/******************************************************************
 * 
 *   baccus Home 모듈
 *   2022.02.23 ~ 2022.02.23
 *   
 *   Copyright 2023 by YoungBin KO.
 * 
 * ****************************************************************/

import React, { useEffect, useState } from 'react';
import { Table as Table2, Empty, Space, Modal, Image } from 'antd';
import { Button, Table } from 'semantic-ui-react';

import getDataList from '../../common-lib/getDataList';

import { CloseOutlined } from '@ant-design/icons';

import { GOODS_IMAGES_URL } from '../../config/config';

function RegisteredImages() {

    const [ data, setData ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ currentRecord, setCurrentRecord ] = useState({});
    const [ openDrawer, setOpenDrawer ] = useState(false);
    const [ visible, setVisible ] = useState(false)
    const [ mode, setMode ] = useState('View');
    const [ drawerTitle, setDrawerTitle ] = useState('');

    const locale = { emptyText : <><Empty />요청한 데이터가 없습니다.</> };

    const columns = [
        {   title: 'No.',             dataIndex: 'rowNum',            key: 'rowNum',            align: 'right',           width: 30,        },
        {   title: '상품명',           dataIndex: 'goodsName',       key: 'goodsName',       align: 'left',          width: 120,        },
        {   title: '제조사/공급사',    dataIndex: 'companyName',       key: 'companyName',       align: 'center',          width: 50,        },
        {   title: '구분',            dataIndex: 'category',       key: 'category',       align: 'center',          width: 40,        },
        {   title: '용량',            dataIndex: 'volume',       key: 'volume',       align: 'center',          width: 40,        },
        {   title: '도수',            dataIndex: 'alcoholRate',       key: 'alcoholRate',       align: 'center',          width: 40,        },
        {   title: '본수',            dataIndex: 'bottlePerBox',       key: 'bottlePerBox',       align: 'center',          width: 40,        },
        {   title: '판매구분',        dataIndex: 'classification',       key: 'classification',       align: 'center',          width: 40,        },
        {   title: '상품 이미지',     dataIndex: 'imgFilename',         key: 'imgFilename',         align: 'center',          width: 70,
            render : (_, record) => {
                const url = GOODS_IMAGES_URL + `${record.imgFilename}`;
                return <Image src={url} height={150} onClick={(e) => {e.stopPropagation()}} />;
            }
        },
        {   title: '상품상태',        dataIndex: 'goodsStatus',       key: 'goodsStatus',       align: 'center',          width: 40,
            render : (text) => {
                return text === 'Y' ? '사용' : '사용불가'; 
            },
        },
        {
            title: '상품설명',        dataIndex: 'description',      key: 'description',        align: 'left', width: 90,
            render: (text) => {
                let cutString;
                if (text && text.length > 80) { cutString = text.substr(0, 80) + '...'; }

                return cutString;
            }
        }
    ];

    const endpoint = "/goodslist";
    const tableWidth = 1600;
    const drawerWidth = 600;

    useEffect(() => {
        getDataList(endpoint, setData, setLoading);
    }, [])

    const goodsInfo = (record) => {
        const url = GOODS_IMAGES_URL + `${record.imgFilename}`;
        Modal.info({
            title: '상품 상제 정보 보기',
            style: {borderRadius: 10},
            content: (
                <div style={{padding:20}}>
                    <Table basic='very' celled collapsing style={{alignItems:'center', width:'100%'}}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell style={{width:120, textAlign:'center'}}>제품명</Table.Cell>
                                <Table.Cell style={{width:120, textAlign:'left'}}>{record.goodsName}</Table.Cell>
                                <Table.Cell style={{width:120, textAlign:'center'}}>제조사 / 공급사</Table.Cell>
                                <Table.Cell style={{width:120, textAlign:'left'}}>{record.companyName}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell style={{width:120, textAlign:'center'}}>구분</Table.Cell>
                                <Table.Cell style={{width:120, textAlign:'left'}}>{record.category}</Table.Cell>
                                <Table.Cell style={{width:120, textAlign:'center'}}>판매구분</Table.Cell>
                                <Table.Cell style={{width:120, textAlign:'left'}}>{record.classification}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell style={{width:120, textAlign:'center'}}>용량</Table.Cell>
                                <Table.Cell style={{width:120, textAlign:'left'}}>{record.volume} ml</Table.Cell>
                                <Table.Cell style={{width:120, textAlign:'center'}}>도수</Table.Cell>
                                <Table.Cell style={{width:120, textAlign:'left'}}>{record.alcoholRate} 도</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell style={{width:120, textAlign:'center'}}>상품상태</Table.Cell>
                                <Table.Cell colSpan='3' style={{width:120, textAlign:'left'}}>{record.goodsStatus === 'Y' ? '사용' : '사용불가'}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell style={{width:120, textAlign:'center'}}>상품 이미지</Table.Cell>
                                <Table.Cell colSpan='3'  style={{width:120, textAlign:'center'}}>
                                    <img src={url} height={400} />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell style={{width:120, textAlign:'center'}}>상품설명</Table.Cell>
                                <Table.Cell colSpan='3'  style={{width:120, textAlign:'left'}}>{record.description}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>          
                </div>
            ),
            width: 800,
            onOk() {},
        });
    }

    return (
        <div className="RegisteredImages" style={{ width:'100%', display:'flex', justifyContent:'flex-start', alignItems: 'center', flexDirection: 'column' }} >
            <Table2 locale={locale} bordered dataSource={data} columns={columns} loading={loading} pagination={{ pageSize: 10, showSizeChanger: false }} 
                   size='small' scroll={{ x: true, y: 520 }} style={{width : tableWidth}}
                   onRow={(record) => {
                        return {
                            onClick : () => { setCurrentRecord(record); goodsInfo(record); }
                        }
                   }} 
            />
        </div>
     );
}
 
export default RegisteredImages;