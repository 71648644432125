import React from 'react';


function GoodsNewAdd() {

    return(
        <>
        </>
    );
}
export default GoodsNewAdd;